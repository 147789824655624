<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" @datachange="getList" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag"
            @datachange="getList"
            >
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <!-- 폴더 목록 -->
        <c-tree-table
          title="LBL0010589"
          parentProperty="opmParentFolderId"
          customID="opmFolderId"
          :columns="gridFolder.columns"
          :data="gridFolder.data"
          :columnSetting="false"
          :usePaging="false"
          :expandAll="true"
          @linkClick="rowClick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add_circle" @btnClicked="openPop" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template>
              <q-chip
                v-if="col.name==='rowFolder'"
                outline square
                color="amber"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="() => openPop(props.row, 'ROWNEW')">
                ▼New
              </q-chip>
              <q-chip
                v-if="col.name==='editFolder'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="chipClick(props.row, col, props.pageIndex)">
                Edit
              </q-chip>
              <q-chip
                v-if="col.name==='openFolder'"
                outline square
                color="green"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="rowClick(props.row)">
                Open▶
              </q-chip>
            </template>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 지침서 목록 -->
        <c-table
          title="LBL0010590"
          rowKey="id"
          :columns="gridProcedure.columns"
          :data="gridProcedure.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="bookmark" color="green" text-color="white" /> 
                  {{ $label('LBL0010591') + " : " + folderName}}  <!-- 폴더명 -->
              </q-chip>
            </q-btn-group>
          </template>
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <!-- 지침서 등록 -->
            <q-btn-group outline >
              <c-btn v-if="editable && folderName" label="LBL0010592" icon="add" @btnClicked="procedureAdd" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';

export default {
  name: 'oper-pro-management',

  data() {
    return {
      gridFolder: {
        columns: [
          {
            name: 'plantFolderName',
            field: 'plantFolderName',
            // 폴더명
            label: 'LBL0010591',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'rowFolder',
            field: 'rowFolder',
            // 하위폴더생성
            label: 'LBL0010593',
            align: 'center',
            style: 'width:80px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'editFolder',
            field: 'editFolder',
            // 폴더수정
            label: 'LBL0010594',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'openFolder',
            field: 'openFolder',
            // 폴더열기
            label: 'LBL0010595',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '362px'
      },
      gridProcedure: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'folderDetailName',
            field: 'folderDetailName',
            // 절차서 제목
            label: 'LBL0010596',
            type: "link",
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'standardDate',
            field: 'standardDate',
            // 기준일
            label: 'LBL0010597',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'file',
          //   field: 'file',
          //   label: '파일첨부',
          //   type: 'custom',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      folderName: '',
      opmFolderId: ''
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.opm.folder.list.url;
      this.listDetailUrl = selectConfig.sop.opm.folderDetail.list.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridFolder.data = _result.data;
        this.gridProcedure.data = []
      },)
    },
    /* eslint-disable no-unused-vars */
    rowClick(row) {
      this.opmFolderId = row.opmFolderId;
      this.folderName = row.folderName;
      this.$http.url = this.$format(this.listDetailUrl, row.opmFolderId);
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridProcedure.data = _result.data
      },)
    },
    chipClick(row, col, index) {
      if (!row) return;
      this.openPop(row, 'DETAIL');
    },
    openPop(data, type) {
      let param = {};
      if (type === 'ROWNEW') {
        param = {
          opmFolderId: '',
          opmParentFolderId: data.opmFolderId,
        }
      } else if (type === 'DETAIL') {
        param = {
          opmFolderId: data.opmFolderId,
        }
      } else {
        param = {
          opmFolderId: '',
        }
      }
      this.popupOptions.title = 'LBL0010598'; // 공정안전보고서 폴더 등록
      this.popupOptions.param = param
      this.popupOptions.target = () => import(`${'./processSafetyReportFolder.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    procedureAdd() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0010599"; // 공정안전보고서 상세
      this.popupOptions.param = {
        opmFolderDetailId: row ? row.opmFolderDetailId : '' ,
        opmFolderId: this.opmFolderId ? this.opmFolderId : '' ,
      };
      this.popupOptions.target = () =>
        import(`${"./processSafetyReportDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.closeCallback = this.closeDetailPopup;
    },  
    closeDetailPopup() {
        
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.rowClick({ opmFolderId: this.opmFolderId, folderName: this.folderName})
    }
  }
};
</script>
